<template>
  <div class="personCenContainer">
    <div class="personBg"></div>
    <div class="abslteBg">
      <div class="personTop">
        <div class="personCont">
          <div class="presonAvar">
            <img style="border-radius: 100%" :src="cappicurl" />
          </div>
          <div class="presonInfo">
            <div class="personCon">
              <div class="personCon_child">
                <h2>{{ userInfo.empname }}</h2>
                <p>{{ userInfo.empcode }}</p>
                <p style="color:red">当天班次:{{ shiftname }}</p>
                <p>{{ shopname }}</p>
                <p>店铺编码:{{ shopid }} 店长：{{ shopmanagerempname }}</p>
                <p>纬度:{{ latitude.toFixed(2) }}</p>
                <p>经度:{{ longitude.toFixed(2) }}</p>
              </div>
            </div>
          </div>
          <div class="tool">
            <div class="time">{{ sqlTime }}</div>
            <van-uploader
              accept="image/*"
              capture="camera"
              :max-count="1"
              :after-read="afterRead2"
              v-if="isshopmanager"
              :disabled="!canSign2"
            >
              <van-button
                type="info"
                size="small"
                plain
                hairline
                :disabled="!canSign2"
                >店长代签</van-button
              >
            </van-uploader>
            <br v-else />
            <van-button
              type="primary"
              size="small"
              hairline
              v-if="setlocal"
              @click="_papp_getshoplocation"
              >获取店铺位置</van-button
            >
            <!--  -->
            <van-button
              type="info"
              :icon="'arrow-down'"
              :icon-position="'right'"
              size="small"
              @click="show = true"
              >{{ date }}</van-button
            >
          </div>
        </div>
      </div>
      <van-uploader
        class="mySign"
        accept="image/*"
        capture="camera"
        :max-count="1"
        :after-read="afterRead1"
      >
        <van-button type="info">我要签到</van-button>
      </van-uploader>
      <div class="personList">
        <div class="btn_group" v-for="(item, index) in list" :key="index">
          <div class="nameTime">{{ item.checktype }} {{ item.time }}</div>
          <div class="address">
            <van-icon name="location" />{{ item.remark }}
          </div>
          <van-image
            v-if="item.pic"
            width="100"
            height="100"
            :src="item.pic"
            @click="_ImagePreview(item.pic)"
          />
        </div>
      </div>
    </div>

    <van-calendar :min-date="minDate" v-model="show" @confirm="onConfirm" />
    <van-popup v-model="s1" round position="bottom">
      <van-picker
        show-toolbar
        :columns="shoplist"
        value-key="partname"
        :confirm-button-text="$t('module.confirm')"
        :cancel-button-text="$t('module.cancel')"
        @cancel="s1 = false"
        @confirm="onConfirmShop"
      />
    </van-popup>
    <van-popup v-model="s2" round position="bottom">
      <van-picker
        show-toolbar
        :columns="emplist"
        value-key="partname"
        :confirm-button-text="$t('module.confirm')"
        :cancel-button-text="$t('module.cancel')"
        @cancel="s2 = false"
        @confirm="onConfirmEmp"
      />
    </van-popup>
    <van-popup v-model="s3" round position="bottom">
      <van-picker
        show-toolbar
        :loading="loading"
        :columns="typelist"
        value-key="partname"
        :confirm-button-text="$t('module.confirm')"
        :cancel-button-text="$t('module.cancel')"
        @cancel="s2 = false"
        @confirm="onConfirmType"
      />
    </van-popup>
    <van-popup v-model="s4" round position="bottom">
      <van-datetime-picker
        :loading="loading"
        v-model="currentTime"
        type="datetime"
        title="选择签到时间"
        :min-date="minDate"
        @confirm="onConfirmTime"
      />
    </van-popup>
  </div>
</template>
<script>
import { Toast, ImagePreview, ref, Dialog } from 'vant'
import { parseTime, GetDistance } from '@/utils'
import { getDdSigurate } from '@api/dingding.js'
import {
  GetJsSign,
  PhoneSign,
  managerPhoneSign,
  ini,
  getData,
  papp_getshoplocation
} from '@api/hp.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      minDate: new Date(2020, 0, 1),
      cappicurl: require('@assets/img/loginLogo.png'),
      interval: null,
      show: false,
      date: parseTime(new Date(), '{y}/{m}/{d}'),
      currentTime: new Date(),
      latitude: 0,
      longitude: 0,
      list: [],
      shoplist: ['调动出发', '调动到达'],
      emplist: [],
      typelist: ['上班', '下班', '调动出发', '调动到达'],
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      loading: false,
      base64: '',
      shopname: '',
      shopid: '',
      shopmanagerempname: '',
      shiftname: '',
      isshopmanager: 0,
      setlocal: false,
      datainfo2: [],
      datainfo3: [],
      checktype: '', // 代签类型
      empid: '', // 代签员工empid
      checklimit: 1000,
      local: [],
      local2: [],
      checktime: new Date(),
      sqlTime: ''
    }
  },
  created() {
    this._GetJsSign()
    this._ini()
  },
  computed: {
    canSign1() {
      // 是否允许员工签到
      let b = false
      let array = [...this.local, ...this.local2]
      array.forEach(e => {
        if (
          GetDistance(e.lat, e.lng, this.latitude, this.longitude) <
          e.checklimit
        ) {
          b = true
        }
      })
      // 调动出发不判断出发地点
      if (this.local2 > 0) b = true
      return b
    },
    canSign2() {
      // 是否允许店长代签
      let b = false
      let array = [...this.local]
      array.forEach(e => {
        if (
          GetDistance(e.lat, e.lng, this.latitude, this.longitude) <
          e.checklimit
        ) {
          b = true
        }
      })
      return b
    }
  },
  mounted() {
    let _that = this
    setInterval(function() {
      _that.checktime.setSeconds(_that.checktime.getSeconds() + 1)
      _that.sqlTime = parseTime(_that.checktime)
    }, 1000) // 设置间隔为1秒（1000毫秒）
  },
  methods: {
    // 获取界面信息
    _ini() {
      if (this.userInfo.empname == '张峰茂') {
        alert('获取界面信息')
      }
      ini({ empid: this.userInfo.empid }).then(res => {
        this.shopname = res.datainfo[0].shopname
        this.shopid = res.datainfo[0].shopid
        this.isshopmanager = res.datainfo[0].isshopmanager
        this.setlocal = res.datainfo[0].setlocal
        this.shopmanagerempname = res.datainfo[0].shopmanagerempname
        this.shiftname = res.datainfo[0].shiftname
        this.checklimit = res.datainfo[0].checklimit
        const tt = res.datainfo[0].checktime
          .replace('T', ' ')
          .replace(/-/g, '/')
          .split('.')[0]
        this.checktime = new Date(tt)
        this.local = [
          {
            lng: res.datainfo[0].lng,
            lat: res.datainfo[0].lat,
            checklimit: res.datainfo[0].checklimit
          }
        ]
        this.datainfo2 = res.datainfo2
        this.datainfo3 = res.datainfo3
        this.local2 = []
        res.datainfo4.forEach(e => {
          this.local2.push({
            lng: e.arrive_lng,
            lat: e.arrive_lat,
            checklimit: e.checklimit
          })
          this.local2.push({
            lng: e.start_lng,
            lat: e.start_lat,
            checklimit: e.checklimit
          })
        })
        this.typelist = res.datainfo5.map(e => {
          return e.checktype
        })
        this._getData()
      })
    },

    async _GetJsSign() {
      // let mm = encodeURIComponent(location.href)
      //在vue里，我们把encodeURIComponent去掉了，反倒问题解决了
      let mm = location.href
      if (mm.indexOf('#') > -1) mm = mm.split('#')[0]
      let that = this
      getDdSigurate({
        url: encodeURIComponent(mm),
        entid: localStorage.mulEntid
      }).then(res => {
        if (window.dev) {
          alert(JSON.stringify(res))
        }
        const j = {
          appId: res.agentId, // 必填，微应用ID
          agentId: res.agentId, // 必填，微应用ID
          corpId: res.corpId, //必填，企业ID
          timeStamp: res.sTimeStamp, // 必填，生成签名的时间戳
          nonceStr: res.noncestr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名
          type: 0, //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
          jsApiList: ['device.geolocation.get']
        }

        dd.config(j)
        dd.ready(() => {
          if (window.dev) {
            alert('dd.ready')
          }
          dd.device.geolocation.get({
            targetAccuracy: 200,
            coordinate: 1,
            withReGeocode: true,
            useCache: true, //默认是true，如果需要频繁获取地理位置，请设置false
            onSuccess: result => {
              if (window.dev) {
                alert(JSON.stringify(result))
              }
              that.latitude = result.latitude
              that.longitude = result.longitude
              if (window.dev) {
                alert(that.latitude)
                alert(that.longitude)
              }
            },
            onFail: err => {
              if (window.dev) {
                alert(JSON.stringify(err))
              }
            }
          })
        })
        dd.error(error => {
          if (window.dev) {
            alert(
              JSON.stringify(error) + JSON.stringify(res) + window.globalurls
            )
          }
        })
      })
    },
    // 日历关闭回调
    onConfirm(date) {
      this.date = parseTime(date, '{y}/{m}/{d}')
      this.show = false
      this._getData()
    },
    // 获取考勤记录
    _getData() {
      getData({ empid: this.userInfo.empid, kqdate: this.date }).then(res => {
        this.list = res.datainfo
        this.iniDate(this.list)
      })
    },
    // 格式化每天签到数据
    iniDate() {
      this.list.forEach(e => {
        e.time = e.kqdate.split('T')[0] + ' ' + e.kqtime + ':' + e.kqsec
        e.pic =
          cloundserver +
          '/AttachFile/m610/m_610_' +
          e.autoid +
          '_' +
          e.sfilename +
          e.fileext
      })
    },
    // 拍照1（签到）
    async afterRead1(file) {
      if (this.userInfo.empname == '张峰茂') {
        alert('点击我要签到')
      }
      this.base64 = file.content
      if (this.userInfo.empname == '张峰茂') {
        alert('图标片base64：' + this.base64)
      }
      this._ini()
      if (this.userInfo.empname == '张峰茂') {
        alert('经纬度:' + this.longitude + '|' + this.latitude)
      }
      if (this.longitude == 0 || this.latitude == 0) {
        Toast.fail('获取坐标失败，请刷新界面后继续操作')
      } else {
        this.s3 = true
        if (this.userInfo.empname == '张峰茂') {
          Toast.fail(alert('s3:' + this.s3))
        }
      }
    },
    // 拍照2（代签）
    async afterRead2(file) {
      this.base64 = file.content
      if (this.longitude == 0 || this.latitude == 0) {
        Toast.fail('获取坐标失败，请刷新界面后继续操作')
      } else {
        this.s1 = true
      }
    },
    // 预览图片
    _ImagePreview(pic) {
      ImagePreview([pic])
    },
    // 店长选择代签类型
    onConfirmShop(value) {
      this.checktype = value
      this.emplist =
        value == '调动到达'
          ? this.datainfo2.map(e => {
              return e.empname
            })
          : this.datainfo3.map(e => {
              return e.empname
            })
      this.s1 = false
      this.s2 = true
    },
    // 选择员工
    onConfirmEmp(value, index) {
      this.empid =
        this.checktype == '调动到达'
          ? this.datainfo2[index].empid
          : this.datainfo3[index].empid
      this.s2 = false
      this.s4 = true
    },
    onConfirmTime() {
      this.loading = true
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      managerPhoneSign({
        shopmanagerempid: this.userInfo.empid,
        empid: this.empid,
        checktype: this.checktype,
        pic: this.base64.split(';base64,')[1],
        checkdatetime: parseTime(this.currentTime, null),
        lng: this.longitude,
        lat: this.latitude
      }).then(res => {
        if (res.error == '1') {
          Toast.fail(res.msg)
        } else {
          Toast.success('代签成功！')
        }
        this.loading = false
        this.s4 = false
      })
    },
    // 选择签到类型
    onConfirmType(value) {
      if (value != '调动出发') {
        let b = false
        let array = [...this.local, ...this.local2]
        array.forEach(e => {
          if (
            GetDistance(e.lat, e.lng, this.latitude, this.longitude) <
            this.checklimit
          ) {
            b = true
          }
        })
        if (!b) {
          Toast.fail('不在签到范围！')
          this.s3 = false
          return
        }
      }
      this.loading = true
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      PhoneSign({
        empid: this.userInfo.empid,
        checktype: value,
        pic: this.base64.split(';base64,')[1],
        lng: this.longitude,
        lat: this.latitude,
        time: parseTime(new Date())
      }).then(res => {
        if (res.error == '1') {
          Toast.fail(res.msg)
        } else {
          Toast.success('签到成功！')
        }
        this._getData()
        this.loading = false
        this.s3 = false
      })
    },
    _papp_getshoplocation() {
      Dialog.confirm({
        title: '获取店铺位置',
        message: '位置只能保存1次，确认当前位置正确吗？'
      })
        .then(() => {
          // on confirm
          this.loading = true
          Toast.loading({
            message: '加载中...',
            forbidClick: true
          })
          papp_getshoplocation({
            shopmanagerempid: this.userInfo.empid,
            lng: this.longitude,
            lat: this.latitude
          }).then(res => {
            if (res.error == '1') {
              Toast.fail(res.msg)
            } else {
              Toast.success('保存成功！')
              this._ini()
            }
            this.loading = false
          })
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>

<style scoped lang="less">
.personCenContainer {
  width: 100%;
  height: 100vh;
  position: relative;
}
.personBg {
  width: 100%;
  height: 142px;
  background: #2b8df0;
  position: relative;
  z-index: 1;
}
.abslteBg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  overflow: hidden;
}
.personTop {
  // width: 92%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
  background: #ffffff;
  border-radius: 16px;
  margin: 0 30px;
  margin-top: 40px;
  .personCont {
    padding: 40px 40px 40px 40px;
    display: flex;
  }
}
.presonAvar {
  width: 122px;
  min-width: 122px;
  height: 122px;
  border-radius: 122px;
  border: 2px solid #2b8df0;
  img {
    width: 100%;
    height: 100%;
  }
}
.presonInfo {
  width: 100%;
  height: 300px;
  display: flex;
}
.tool {
  width: 500px;
  .time {
    font-size: 20px;
  }
  .van-button {
    width: 100%;
  }
  .van-uploader {
    width: 100%;

    /deep/ .van-uploader__input-wrapper {
      width: 100%;
      .van-button {
        width: 100%;
      }
    }
  }
}
.personCon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 15px;
  .personCon_child {
    width: 100%;
    h2 {
      margin: 0;
      font-size: 34px;
      padding: 0;
      line-height: 48px;
      color: #333333;
      width: 100%;
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      padding: 0;
      color: #999999;
      font-size: 24px;
      width: 100%;
    }
  }
}
.personList {
  height: calc(100% - 600px);
  overflow: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.23);
  background: #ffffff;
  border-radius: 16px;
  margin: 0 30px;
  padding: 20px;
  .btn_group:first-child {
    margin-top: 0;
  }
  .btn_group {
    border-bottom: 1px #ccc dotted;
    font-size: 30px;
    padding: 20px 0;
    .nameTime {
      font-weight: bold;
    }
    .address {
      color: #999;
    }
  }
}
.van-uploader.mySign {
  .van-uploader__wrapper {
    .van-uploader__input-wrapper {
      .van-button {
        width: calc(100vw - 60px);
        margin: 10px 30px;
      }
    }
  }
}
</style>
